import React from "react"
import styled from "styled-components"
import { Section, SectionWrapper, Text, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"
import { OnDesktop, OnMobile } from "../../DesktopMobileComps"

import Fire from "../../../assets/images/campaigns/safety-plan-threats/fire.svg"
import Floods from "../../../assets/images/campaigns/safety-plan-threats/floods.svg"
import Theft from "../../../assets/images/campaigns/safety-plan-threats/theft.svg"
import CCTV from "../../../assets/images/campaigns/safety-plan-threats/cctv.svg"
import FireExtinguisher from "../../../assets/images/campaigns/safety-plan-threats/fire-extinguisher.svg"

const InfoCard = ({title, desc, type, iconClassName, mobileBg, fixedWidth=false}) => { 
  let icons = null
  if (type === "insurance") {
    icons = (
      <InfoCardIconsContainer>
        <InfoCardIconContainer><Fire className={iconClassName} /></InfoCardIconContainer>
        <InfoCardIconContainer><Floods className={iconClassName} /></InfoCardIconContainer>
        <InfoCardIconContainer><Theft className={iconClassName} /></InfoCardIconContainer>
      </InfoCardIconsContainer>
    )
  } else if (type === "safety") {
    icons = (
      <InfoCardIconsContainer>
        <InfoCardIconContainer><CCTV className={iconClassName} /></InfoCardIconContainer>
        <InfoCardIconContainer><FireExtinguisher className={iconClassName} /></InfoCardIconContainer>
      </InfoCardIconsContainer>
    )
  }
  
  return (
    <InfoCardContainer fixedWidth={fixedWidth} mobileBg={mobileBg}>
      {icons}
      <Text fontSize="20px" lineHeight="24px" mfontSize="18px" mlineHeight="24px" fontWeight="bold">
        {title}
      </Text>
      <Text fontSize="18px" lineHeight="36px" mfontSize="16px" mlineHeight="24px" fontWeight="500">
        {desc}
      </Text>
    </InfoCardContainer>
  )
}

const SafeguardYourBusiness = ({
  handleGetQuoteClick = () => {},
  quoteCtaProps={},
  showGetQuote = false,
  safetyPlan=false,
  style={},
  mobileStyles={background: "#F6F9FF"},
  desktopStyles={background: "#fff"},
  iconClassName = "",
  mobileItemCardBg = "#F6F9FF"
}) => {
  const {label, uppercaseLabel} = quoteCtaProps
  return (
    <Section desktopStyles={desktopStyles} mobileStyles={mobileStyles} style={style}>
      <SectionWrapper>
        <Title fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="22px" style={{textAlign: "center"}} desktopStyles={{marginBottom: "60px"}} mobileStyles={{marginBottom: '32px'}}>
          Safeguard your business from present and future threats
        </Title>
        <InfoItemsContainer>
          <InfoCard 
            mobileBg={mobileItemCardBg} 
            iconClassName={iconClassName} 
            type="insurance" title="Insurance for 15+ risks" 
            desc={`Including fire, theft, flood and riots. ${!safetyPlan ? "Plans start at only ₹8 per day." : ""}`}
          />
          <InfoCard 
            mobileBg={mobileItemCardBg} 
            iconClassName={iconClassName} 
            type="safety" 
            title="Safety equipment for all-round protection" 
            desc="CCTV cameras and Fire Extinguishers to keep your business safe from harm"
          />
        </InfoItemsContainer>
        {showGetQuote ? (
          <>
            <OnDesktop><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "98px"}} onClick={handleGetQuoteClick} /></OnDesktop>
            <OnMobile><Button type="flatdesign" label={label || "Get a Quote"} uppercaseLabel={uppercaseLabel || false} style={{margin: "auto", marginTop: "36px", padding: '10px 16px'}} onClick={handleGetQuoteClick} /></OnMobile>
          </>
        ) : null}
      </SectionWrapper>
    </Section>
  )
}

const InfoCardContainer = styled.article`
  position: relative;
  padding: 20px;
  width: min(500px, 100%);
  background: #FFFFFF;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
  @media (max-width: 768px) {
    width: ${p => p.fixedWidth ? "184px" : "min(400px, 100%)"};
    min-width: ${p => p.fixedWidth ? "184px" : "auto"};
    background: ${p => p.mobileBg || "#F6F9FF"};
    text-align: left;
    box-shadow: none;
    padding: 0 1rem;
    .icon {
      width: 84px;
    }
  }
`

const InfoCardIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`

const InfoCardIconContainer = styled.div`
  border-radius: 4px;
  svg {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 768px) {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`

export const InfoItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    overflow: auto;
    align-items: center;
    margin-top: 40px;
  }
`

export default SafeguardYourBusiness